/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CoreAppState } from '@ws1c/intelligence-core/store';
import {
  UserPreferenceSelectors,
  UserPreferenceTrialInfoSelectors,
  UserPreferenceUIPreferenceSelectors,
} from '@ws1c/intelligence-core/store/user-preference';
import { ROUTE_NAMES } from '@ws1c/intelligence-models';

/**
 * Make sure user can access Dashboards feature only when at least one dashboard is enabled
 * and dashboard intro page is already visited
 * @export
 * @returns {Observable<boolean>}
 */
export function dashboardGuard(): Observable<boolean> {
  const store = inject(Store<CoreAppState>);
  const router = inject(Router);
  return combineLatest([
    store.select(UserPreferenceSelectors.isAnyDashboardFeaturesEnabled),
    store.select(UserPreferenceUIPreferenceSelectors.isDashboardIntroPageVisited),
    store.select(UserPreferenceTrialInfoSelectors.isTrialApplicable),
  ]).pipe(
    map(([isFeatureEnabled, isDashboardIntroPageVisited, isTrialApplicable]: [boolean, boolean, boolean]) => {
      if (isFeatureEnabled && isDashboardIntroPageVisited) {
        return true;
      } else if (isFeatureEnabled || isTrialApplicable) {
        router.navigate([`/${ROUTE_NAMES.DASHBOARD.INTRO}`]);
      } else {
        router.navigate([`/${ROUTE_NAMES.ACCESS_DENIED}`]);
      }
      return false;
    }),
  );
}
