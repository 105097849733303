/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CoreAppState } from '@ws1c/intelligence-core/store';
import {
  UserPreferenceFeatureControlsSelectors,
  UserPreferenceTrialInfoSelectors,
  UserPreferenceUIPreferenceSelectors,
} from '@ws1c/intelligence-core/store/user-preference';
import { ROUTE_NAMES } from '@ws1c/intelligence-models';

/**
 * Make sure user can access Automations feature only when automation
 * is enabled and automation intro page is already visited
 * @export
 * @returns {Observable<boolean>}
 */
export function automationGuard(): Observable<boolean> {
  const store = inject(Store<CoreAppState>);
  const router = inject(Router);
  return combineLatest([
    store.select(UserPreferenceFeatureControlsSelectors.isAutomationEnabled),
    store.select(UserPreferenceUIPreferenceSelectors.isAutomationIntroPageVisited),
    store.select(UserPreferenceTrialInfoSelectors.isTrialApplicable),
  ]).pipe(
    map(([isFeatureEnabled, isAutomationIntroPageVisited, isTrialApplicable]: [boolean, boolean, boolean]) => {
      if (isFeatureEnabled && isAutomationIntroPageVisited) {
        return true;
      } else if (isFeatureEnabled || isTrialApplicable) {
        router.navigate([`/${ROUTE_NAMES.AUTOMATION.INTRO}`]);
      } else {
        router.navigate([`/${ROUTE_NAMES.ACCESS_DENIED}`]);
      }
      return false;
    }),
  );
}
