/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash-es';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { CoreAppState, UserPreferenceSelectors } from '@ws1c/intelligence-core/store';
import { hasScopeOrParentScope, RbacSelectors } from '@ws1c/intelligence-core/store/rbac';
import { ROUTE_NAMES } from '@ws1c/intelligence-models';

/**
 * Make sure user can access pages based on the scopes assigned to him
 * @param {ActivatedRouteSnapshot} route
 * @export
 * @returns {Observable<boolean>}
 */
export function roleGuard(route: ActivatedRouteSnapshot): Observable<boolean> {
  const store = inject(Store<CoreAppState>);
  const router = inject(Router);
  return combineLatest([
    store.select(RbacSelectors.getUserScopesUnmodified).pipe(filter(Boolean)),
    store.select(UserPreferenceSelectors.isBrownfieldNotificationEnabled),
  ]).pipe(
    map(([scopes, isBrownfieldNotificationEnabled]: [string[], boolean]) => {
      const isEnabled =
        route.data && !isEmpty(route.data.expectedScopes)
          ? route.data.expectedScopes.some((scope: string) => hasScopeOrParentScope(scopes, scope))
          : !isEmpty(scopes);
      if (!isEnabled) {
        const navigationPath: string = isBrownfieldNotificationEnabled
          ? ROUTE_NAMES.CLOUD_ACCOUNT_RESTRICTED
          : ROUTE_NAMES.ACCESS_RESTRICTED;
        router.navigate([`/${navigationPath}`]);
      }
      return isEnabled;
    }),
  );
}
