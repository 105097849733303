/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { GenericObject } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CoreAppState } from '@ws1c/intelligence-core/store';
import { UserPreferenceTrialInfoSelectors, UserPreferenceUIPreferenceSelectors } from '@ws1c/intelligence-core/store/user-preference';
import { IntelligenceFeature, ROUTE_NAMES, UIPreference } from '@ws1c/intelligence-models';

const NAVIGATION_MENU_ITEM_TO_INTRO_PREFERENCE: Partial<Record<IntelligenceFeature, UIPreference>> = {
  [IntelligenceFeature.AUTOMATION]: UIPreference.IS_AUTOMATION_INTRO_PAGE_VISITED,
  [IntelligenceFeature.DASHBOARD]: UIPreference.IS_DASHBOARD_INTRO_PAGE_VISITED,
  [IntelligenceFeature.REPORT]: UIPreference.IS_REPORT_INTRO_PAGE_VISITED,
};

/**
 * Make sure user can hit intro page only if trial
 * is applicable or intro page is never visited
 * @param {ActivatedRouteSnapshot} route - Holds associated route state and data info
 * @export
 * @returns {Observable<boolean>}
 */
export function introGuard(route: ActivatedRouteSnapshot): Observable<boolean> {
  const store = inject(Store<CoreAppState>);
  const router = inject(Router);
  return combineLatest([
    store.select(UserPreferenceTrialInfoSelectors.isTrialApplicable),
    store.select(UserPreferenceUIPreferenceSelectors.uiSettingsPreferences),
  ]).pipe(
    map(([isTrialApplicable, uiSettingsPreferences]: [boolean, GenericObject]) => {
      const feature: IntelligenceFeature = route.data.intelligenceFeature;
      const uiIntroPreferenceKey: UIPreference = NAVIGATION_MENU_ITEM_TO_INTRO_PREFERENCE[feature];
      // Show intro page if in trial mode(not applicable for reports) or intro page is not acknowledged
      if ((isTrialApplicable && feature !== IntelligenceFeature.REPORT) || !uiSettingsPreferences[uiIntroPreferenceKey]) {
        return true;
      }
      router.navigate([`/${ROUTE_NAMES.ACCESS_DENIED}`]);
      return false;
    }),
  );
}
