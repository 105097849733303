/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DEEM_ROUTE_NAMES } from '@ws1c/deem-solution/const';
import { RouterExtensions } from '@ws1c/intelligence-common';
import { SolutionSetupSelectors } from '@ws1c/intelligence-core/store/solution-setup';

/**
 * Handle the deem dashboard by checking if each sub type of solutions is ready
 * @export
 * @returns {Observable<boolean>}
 */
export function deemDashboardGuard(): Observable<boolean> {
  const store = inject(Store);
  const router = inject(RouterExtensions);
  return combineLatest([
    store.select(SolutionSetupSelectors.isDeemPhysicalReady),
    store.select(SolutionSetupSelectors.isDeemVirtualReady),
  ]).pipe(
    map(([isDeemPhysicalReady, isDeemVirtualReady]: [boolean, boolean]) => {
      if (isDeemPhysicalReady) {
        router.navigate([DEEM_ROUTE_NAMES.SUMMARY_PHYSICAL]);
        return true;
      } else if (isDeemVirtualReady) {
        router.navigate([DEEM_ROUTE_NAMES.SUMMARY_VIRTUAL_V2]);
        return true;
      }
      return false;
    }),
  );
}
