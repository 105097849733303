/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CoreAppState, OrgSelectors } from '@ws1c/intelligence-core/store';
import { ROUTE_NAMES } from '@ws1c/intelligence-models';

/**
 * Allow current route only if all onboarding steps are complete
 * @export
 * @returns {Observable<boolean>}
 */
export function onboardingCompleteGuard(): Observable<boolean> {
  const store = inject(Store<CoreAppState>);
  const router = inject(Router);
  return store.select(OrgSelectors.isOnboardingComplete).pipe(
    map((isOnboardingComplete: boolean) => {
      if (isOnboardingComplete) {
        return true;
      }
      router.navigate([`/${ROUTE_NAMES.ONBOARDING_PAGE.HOME}`]);
      return false;
    }),
  );
}
