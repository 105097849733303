/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@ws1c/intelligence-core/services';
import { ROUTE_NAMES } from '@ws1c/intelligence-models';

/**
 * If session is active, redirect to home page
 * @export
 * @returns {Observable<boolean>}
 */
export function loginGuard(): boolean {
  const authService = inject(AuthService);
  const router = inject(Router);
  if (authService.isAuthenticated()) {
    router.navigate([`/${ROUTE_NAMES.WORKSPACE.HOME}`]);
    return false;
  }
  return true;
}
