/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { every, keys } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CoreAppState } from '@ws1c/intelligence-core/store';
import { UserPreferenceCommonSelectors } from '@ws1c/intelligence-core/store/user-preference';
import { FeatureControls, ROUTE_NAMES } from '@ws1c/intelligence-models';

/**
 * Make sure user can access to the feature
 * @param {ActivatedRouteSnapshot} route
 * @export
 * @returns {Observable<boolean>}
 */
export function featureControlGuard(route: ActivatedRouteSnapshot): Observable<boolean> {
  const store = inject(Store<CoreAppState>);
  const router = inject(Router);
  return store.select(UserPreferenceCommonSelectors.getFeatureControls).pipe(
    map((featureControls: FeatureControls) => {
      const enabledFeatureControls = new Set(keys(featureControls).filter((featureKey: string) => featureControls[featureKey]));
      const isEnabled = every(route.data.featureControl, (requiredFeatureControl: string) =>
        enabledFeatureControls.has(requiredFeatureControl),
      );
      if (!isEnabled) {
        router.navigate([`/${ROUTE_NAMES.ACCESS_DENIED}`]);
      }
      return isEnabled;
    }),
  );
}
