/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { GenericObject, WindowService } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfig } from '@ws1c/intelligence-common';
import { AuthService } from '@ws1c/intelligence-core/services';
import { CoreAppState } from '@ws1c/intelligence-core/store';
import { UserPreferenceSelectors } from '@ws1c/intelligence-core/store/user-preference';
import { ROUTE_NAMES, ShareType } from '@ws1c/intelligence-models';

/**
 * Make sure user can access UI only after authenticated
 * @export
 * @returns {Observable<boolean>}
 */
export function authGuard(): Observable<boolean> {
  const authService = inject(AuthService);
  const windowService = inject(WindowService);
  const store = inject(Store<CoreAppState>);
  const router = inject(Router);
  return combineLatest([
    store.select(UserPreferenceSelectors.isNonAdminPage),
    store.select(UserPreferenceSelectors.getInitialUrlQueryParams),
    authService.isEulaAccepted$,
  ]).pipe(
    map(([isNonAdminPage, initialUrlQueryParams, isEulaAccepted]: [boolean, GenericObject, boolean]) => {
      if (isNonAdminPage) {
        router.navigate([`/${ROUTE_NAMES.COMMON.SHARE}`], {
          queryParams: {
            shareType: initialUrlQueryParams?.share_type || ShareType.REPORT,
            shareId: initialUrlQueryParams?.share_id,
            objectId: initialUrlQueryParams?.object_id,
          },
        });
        return false;
      }
      // Only check for Authentication if not in Non-Admin/Sharing mode
      if (!authService.isAuthenticated()) {
        router.navigate([`/${ROUTE_NAMES.LOGIN}`], {
          queryParams: {
            [AppConfig.QUERY_ORIGIN_PATH]: windowService.location.href,
          },
        });
        return false;
      }
      // Handled authenticated user with no EULA accepted
      if (!isEulaAccepted) {
        router.navigate([`/${ROUTE_NAMES.ACCEPT_EULA}`]);
        return false;
      }
      // Allow all authenticated user
      return true;
    }),
  );
}
